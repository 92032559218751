import React, { useEffect } from "react"
import MyOrders from "../../../components/account/pages/my-orders"
import { useTranslationContext } from "../../../context/TranslationContext"

const canonicals = {
  ["en-US"]: "/account/my-orders",
  ["de-DE"]: "/de/konto/meine-bestellungen",
}

const MyOrdersPage = () => {
  const { setTranslationContext } = useTranslationContext()

  useEffect(() => {
    setTranslationContext({
      locale: "de-DE",
      canonicals: canonicals,
    })
  }, [setTranslationContext])
  return <MyOrders />
}

export default MyOrdersPage
